<template>
  <ion-fab class="floating-audio-button">
    <ion-fab-button class="floating-audio-button__waveform">
      <ion-spinner
        v-if="isLoading"
        class="cs-play-pause-button__loading"
        name="circles"
      />
      <template v-else-if="isPlaying">
        <div
          class="floating-audio-button__wave floating-audio-button__wave-1"
        ></div>
        <div
          class="floating-audio-button__wave floating-audio-button__wave-2"
        ></div>
        <div
          class="floating-audio-button__wave floating-audio-button__wave-3"
        ></div>
      </template>
      <i v-else class="cs-icons-play-filled floating-audio-button__play-icon" />
    </ion-fab-button>
  </ion-fab>
</template>
<script>
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState('audio', {
      isPlaying: 'isPlaying',
      isLoading: 'isLoading',
    }),
  },
};
</script>
<style scoped>
.floating-audio-button__waveform {
  display: flex;
  justify-content: center;
  align-items: center;
  --background: var(--cs-primary-base);
  --background-activated: var(--cs-primary-light);
}
.floating-audio-button__wave {
  height: 4px;
  width: 4px;
  margin: 0 2px;
  border-radius: 2px;
  background-color: var(--cs-white);
}
.floating-audio-button__wave-1 {
  animation: wave1 3s linear 0s infinite;
}
.floating-audio-button__wave-2 {
  animation: wave2 2.7s linear 0s infinite;
}
.floating-audio-button__wave-3 {
  animation: wave3 3.3s linear 0s infinite;
}
.floating-audio-button__play-icon {
  font-size: 24px;
}
@keyframes wave1 {
  0% {
    height: 4px;
  }
  10% {
    height: 12px;
  }
  20% {
    height: 8px;
  }
  30% {
    height: 12px;
  }
  40% {
    height: 4px;
  }
  50% {
    height: 8px;
  }
  60% {
    height: 6px;
  }
  70% {
    height: 12px;
  }
  80% {
    height: 8px;
  }
  90% {
    height: 12px;
  }
  100% {
    height: 4px;
  }
}
@keyframes wave2 {
  0% {
    height: 4px;
  }
  15% {
    height: 12px;
  }
  30% {
    height: 20px;
  }
  45% {
    height: 8px;
  }
  60% {
    height: 16px;
  }
  80% {
    height: 20px;
  }
  90% {
    height: 8px;
  }
  100% {
    height: 4px;
  }
}
@keyframes wave3 {
  0% {
    height: 4px;
  }
  11% {
    height: 10px;
  }
  22% {
    height: 6px;
  }
  33% {
    height: 12px;
  }
  44% {
    height: 4px;
  }
  55% {
    height: 8px;
  }
  66% {
    height: 4px;
  }
  77% {
    height: 10px;
  }
  88% {
    height: 12px;
  }
  100% {
    height: 4px;
  }
}
</style>
