<template>
  <ion-page>
    <app-main-header title="Live Music">
      <template v-slot:right>
        <i
          class="cs-icons-history-filled history-icon"
          @click="showPreviouslyPlayedModal = true"
        ></i>
      </template>
    </app-main-header>
    <ion-content>
      <ion-list>
        <div class="er-home__content-wrapper">
          <div>
            <app-audio-player />
            <div
              class="er-home__request-song ion-padding"
              @click="checkGuestRequest"
            >
              <div class="cs-textstyle-informative-paragraph">
                Hear what you love
              </div>
              <cs-button
                class="er-home__request-button--dark"
                fill="outline"
                variant="default"
                corners="rounded"
                @click.stop="showRequestSongModal = true"
                :disabled="isGuest"
                >Request a Song</cs-button
              >
              <cs-button
                class="er-home__request-button--light"
                fill="outline"
                variant="primary"
                corners="rounded"
                @click.stop="showRequestSongModal = true"
                :disabled="isGuest"
                >Request a Song</cs-button
              >
            </div>
          </div>
          <app-push-notifications-button />
          <app-donate-button
            class="er-home--horizontal-padding er-home__donate-buton"
          />
        </div>
      </ion-list>
    </ion-content>
    <app-previously-played-modal
      :show="showPreviouslyPlayedModal"
      @close="showPreviouslyPlayedModal = false"
    />
    <app-request-song-modal
      :show="showRequestSongModal"
      @close="showRequestSongModal = false"
    />
  </ion-page>
</template>

<script>
import AppMainHeader from '@/components/general/MainHeader.vue';
import AppAudioPlayer from '@/components/audio/AudioPlayer.vue';
import AppDonateButton from '@/components/donate/DonateButton.vue';

import AppPreviouslyPlayedModal from '@/components/modals/PreviouslyPlayedModal.vue';
import AppRequestSongModal from '@/components/modals/RequestSongModal.vue';

import AppPushNotificationsButton from '@/components/notifications/PushNotificationsButton.vue';

import { mapGetters, mapState } from 'vuex';

import $dialog from '@/services/dialog';

export default {
  components: {
    AppMainHeader,
    AppAudioPlayer,
    AppDonateButton,
    AppPreviouslyPlayedModal,
    AppRequestSongModal,
    AppPushNotificationsButton,
  },
  data() {
    return {
      showPreviouslyPlayedModal: false,
      showRequestSongModal: false,
    };
  },
  methods: {
    checkGuestRequest() {
      if (this.isGuest) {
        $dialog.alert(
          'For security reasons, guest accounts are not allowed to request songs.  Please go to the Menu page to sign in.  Thank you for understanding.'
        );
      }
    },
  },
  computed: {
    isGuest() {
      if (!this.user || !this.user.is_guest) return false;
      return true;
    },
    ...mapState('auth', {
      user: 'user',
    }),
    ...mapGetters('auth', {
      userId: 'userId',
    }),
  },
  mounted() {
    this.$store.dispatch('nowplaying/fetch');
    this.$store.dispatch('nowplaying/subscribe');
  },
};
</script>

<style scoped>
.er-home--horizontal-padding {
  padding: 0 16px;
}
.er-home--padding {
  padding: 16px;
}
.history-icon {
  color: var(--cs-gray-06) !important;
}
.er-home__request-song {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--er-gray-primary);
}
body.dark .er-home__request-button--light {
  display: none;
}
body.light .er-home__request-button--dark {
  display: none;
}
.er-home__content-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
/* .er-home__content-wrapper div:first-child {
  background: var(--cs-primary-contrast);
} */
.er-home__donate-buton {
  width: 100%;
  margin-top: 20px;
}
</style>
