import { getApolloClient } from '@/vue-apollo';

import InsertTrackReaction from '@/graphql/track/InsertTrackReaction.gql';
import NowPlaying from '@/graphql/general/NowPlaying.gql';
import RadioHistorySubscription from '@/graphql/general/RadioHistorySubscription.gql';

let initialized = false;
let subscription = null;

// Note: we track the song artist, album, reactions, elapsed time, etc here so they can immediately
//   load when the player is loaded.  And if we need to add another mini-player like in CSN the
//   playback information will be in sync

export default {
  namespaced: true,
  state: {
    isFetching: false,
    track: null,
    now: Date.now(),
  },
  getters: {
    reaction(state) {
      if (
        !state.track ||
        !state.track.radio_track_reactions ||
        !state.track.radio_track_reactions.length
      )
        return null;

      const reaction = state.track.radio_track_reactions[0];
      return reaction;
    },
    liked(_, getters) {
      const reaction = getters.reaction;
      return !!(reaction && reaction.reaction === 'like');
    },
    disliked(_, getters) {
      const reaction = getters.reaction;
      return reaction && reaction.reaction === 'dislike';
    },
    showReactions(state) {
      return !!state.track;
    },
    song(state) {
      if (!state.track) return null;
      return state.track.track;
    },
    title(_, getters) {
      if (!getters.song || !getters.song.title) return 'Modern Christian Music';
      return getters.song.title;
    },
    artist(_, getters) {
      if (!getters.song || !getters.song.artist) return 'Various Artists';
      return getters.song.artist;
    },
    picture(_, getters) {
      if (!getters.song || !getters.song.picture)
        return 'https://effectradio.com/img/albums300/default-e.png';
      return getters.song.picture;
    },
    trackLength(state) {
      if (!state.track) return;
      if (!state.track.start_time || !state.track.end_time) return;
      const startTime = new Date(state.track.start_time);
      const endTime = new Date(state.track.end_time);
      return endTime.valueOf() - startTime.valueOf();
    },
    timePassedOfTrack(state, getters) {
      if (!state.track) return;
      if (!state.track.start_time) return;
      if (!getters.trackLength) return;
      const startTime = new Date(state.track.start_time);
      let timePassed = state.now - startTime.valueOf();
      if (timePassed < 0) timePassed = 0;
      if (timePassed > getters.trackLength) timePassed = getters.trackLength;
      return timePassed;
    },
    progressOfShow(_, getters) {
      if (!getters.trackLength || !getters.timePassedOfTrack) return 0;
      return (getters.timePassedOfTrack / getters.trackLength) * 100;
    },
    showTime(_, getters) {
      if (!getters.trackLength || !getters.timePassedOfTrack) return '';
      const displayLengthMinutes = Math.round(getters.trackLength / 1000 / 60);
      const progressSeconds = Math.round(getters.timePassedOfTrack / 1000);
      const displaySeconds = Math.round(progressSeconds % 60);
      const formattedSeconds =
        displaySeconds < 10 ? `0${displaySeconds}` : displaySeconds;
      const displayMinutes = Math.floor(progressSeconds / 60);
      return `${displayMinutes}:${formattedSeconds} / ${displayLengthMinutes} Min`;
    },
  },
  mutations: {
    setTrack(state, trackData) {
      if (trackData && trackData.track) {
        state.track = trackData;
      } else {
        state.track = null;
      }
    },
    setNow(state, timestamp) {
      state.now = timestamp;
    },
    setIsFetching(state, fetching) {
      state.isFetching = fetching;
    },
  },
  actions: {
    init({ commit }) {
      // Only run this once, initialise the interval checker
      // The interval checker does two things:
      // 1) Updates the time elapsed on the song
      // 2) Runs a check to see if the song has finished.
      //   (if the song has finished and there is no new song, set it to null in order to display default information)
      if (initialized) return;
      initialized = true;
      setInterval(() => {
        commit('setNow', Date.now());
        // dispatch('checkTrackNowPlaying');
      }, 1000);
    },
    subscribe({ dispatch }) {
      if (subscription) return;
      subscription = getApolloClient()
        .subscribe({
          query: RadioHistorySubscription,
        })
        .subscribe({
          next({ data }) {
            if (data && data.player_data && data.player_data[0]) {
              dispatch('updateTrack', data.player_data[0]);
            }
          },
          error() {
            subscription = null;
          },
        });
    },
    updateTrack({ commit, dispatch }, radioEntry) {
      // Update the track to the next track.  However, there can sometimes be preloaded songs
      //  in the preloaded case, set a timeout until around when the new song should start
      if (radioEntry) {
        const endTime = new Date(radioEntry.end_time);
        const timeSinceEnd = Date.now() - endTime.getTime();
        // set it to null if more than 3 seconds past since end of track
        if (timeSinceEnd > 3000) {
          return commit('setTrack', null);
        }
      }
      if (radioEntry && radioEntry.track) {
        const startTime = new Date(radioEntry.start_time);
        const timeDifference = startTime.getTime() - Date.now();
        if (timeDifference > 1000) {
          setTimeout(() => {
            dispatch('updateTrack', radioEntry);
          }, timeDifference - 1000);
        } else {
          commit('setTrack', radioEntry);
        }
      } else {
        commit('setTrack', null);
      }
    },
    async react({ commit, state, getters, rootGetters }, reaction) {
      // If there is no track to react to, return
      const track = state.track;
      if (!track) return;

      const currentReaction = getters.reaction;
      if (!currentReaction) {
        // If there is no current reaction from the user, the current reaction made will be saved
      } else if (currentReaction.reaction === reaction) {
        // If there was a previous reaction and the user previously reacted in this way, treat it as a toggle
        // So "untoggle" the user reaction (if the user previously liked it, pressing like again will remove the like)
        reaction = '';
      }

      track.radio_track_reactions = [{ reaction }];
      commit('setTrack', track);

      const userId = rootGetters['auth/userId'];
      if (!userId) return;

      getApolloClient().mutate({
        mutation: InsertTrackReaction,
        variables: {
          userId,
          reaction,
          radioTrackId: track.id,
        },
      });
    },
    async fetch({ state, commit, dispatch }) {
      if (state.isFetching) return;
      commit('setIsFetching', true);
      try {
        const {
          data: { track },
        } = await getApolloClient().query({
          query: NowPlaying,
          fetchPolicy: 'network-only',
        });
        if (track && track[0]) {
          dispatch('updateTrack', track[0]);
        }
      } catch (e) {
        //
      }
      commit('setIsFetching', false);
    },
  },
};
