<template>
  <div class="floating-audio-player" v-show="audioInitialised">
    <transition name="fade-in-out">
      <app-floating-audio-button
        v-if="!playerOpen"
        @click="floatingButtonClick"
        :animated="audioPlaying"
        class="floating-audio-player__floating-button"
      />
    </transition>
    <transition name="slide-in-out">
      <div v-if="playerOpen" class="floating-audio-player__content ion-padding">
        <div class="csn-show-list-item__content">
          <div v-if="picture" class="csn-show-list-item__image-holder">
            <img class="csn-show-list-item__image" :src="picture" />
          </div>
          <div class="csn-show-list-item__titles">
            <div class="csn-show-list-item__title cs-textstyle-detail-heading">
              {{ title }}
            </div>
            <div class="csn-show-list-item__subtitle cs-textstyle-paragraph">
              {{ artist }}
            </div>
          </div>
          <app-play-pause-button />
        </div>
        <div class="floating-audio-player__progress-holder">
          <app-progress-bar
            v-if="song"
            class="floating-audio-player__progress"
            :progress="progressOfShow"
          />
        </div>

        <div class="floating-audio-player__bottom-row">
          <div class="cs-textstyle-tags floating-audio-player__time">
            <span v-if="song">{{ showTime }}</span>
          </div>
          <!-- <template v-if="song">
            <div class="floating-audio-player__reaction-holder">
              <i
                class="floating-audio-player__like cs-icons-like"
                :class="{ 'cs-icons-like-filled': liked }"
                @click="onReaction('like')"
              ></i>
              <i
                class="floating-audio-player__dislike cs-icons-like"
                :class="{ 'cs-icons-like-filled': disliked }"
                @click="onReaction('dislike')"
              ></i>
            </div>
          </template> -->

          <div
            class="cs-textstyle-tags floating-audio-player__hide"
            @click="hidePlayer"
          >
            Hide
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import AppFloatingAudioButton from '@/components/audio/FloatingAudioButton.vue';
import AppPlayPauseButton from '@/components/audio/PlayPauseButton.vue';
// import AppShowListItem from '@/components/shows/ShowListItem.vue';
import AppProgressBar from '@/components/general/ProgressBar.vue';
// import AppSkeletonShowListItem from '@/components/skeletons/SkeletonShowListItem.vue';
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    AppFloatingAudioButton,
    AppPlayPauseButton,
    // AppShowListItem,
    AppProgressBar,
    // AppSkeletonShowListItem,
  },
  computed: {
    ...mapState('audio', {
      audioPlaying: 'isPlaying',
      audioProgress: 'audioProgress',
      audioLoading: 'audioLoading',
    }),
    ...mapState('nowplaying', {
      trackLoading: 'isLoading',
      track: 'track',
      // playingShow: 'liveShow',
      // nextShows: 'nextShows',
    }),
    ...mapGetters('nowplaying', {
      reaction: 'reaction',
      liked: 'liked',
      disliked: 'disliked',
      showReactions: 'showReactions',
      song: 'song',
      title: 'title',
      artist: 'artist',
      picture: 'picture',
      trackLength: 'trackLength',
      timePassedOfTrack: 'timePassedOfTrack',
      progressOfShow: 'progressOfShow',
      showTime: 'showTime',
    }),
    // lengthOfShow() {
    //   if (!this.playingShow || !this.upcomingShow) return;
    //   if (!this.playingShow.time || !this.upcomingShow.time) return;
    //   return this.upcomingShow.time.valueOf() - this.playingShow.time.valueOf();
    // },
    // timePassedOfShow() {
    //   if (!this.playingShow) return;
    //   if (!this.playingShow.time) return;
    //   return this.now - this.playingShow.time.valueOf();
    // },
    // progressOfShow() {
    //   if (!this.lengthOfShow || !this.timePassedOfShow) return 0;
    //   return (this.timePassedOfShow / this.lengthOfShow) * 100;
    // },
    // showTime() {
    //   if (!this.lengthOfShow || !this.timePassedOfShow) return '';
    //   const displayLengthMinutes = Math.ceil(this.lengthOfShow / 1000 / 60);
    //   const progressSeconds = Math.round(this.timePassedOfShow / 1000);
    //   const displaySeconds = Math.round(progressSeconds % 60);
    //   const formattedSeconds =
    //     displaySeconds < 10 ? `0${displaySeconds}` : displaySeconds;
    //   const displayMinutes = Math.floor(progressSeconds / 60);
    //   return `${displayMinutes}:${formattedSeconds} / ${displayLengthMinutes} Min`;
    // },
  },
  data() {
    return {
      now: Date.now(),
      playerOpen: false,
      audioInitialised: true,
      timer: null,
    };
  },
  mounted() {
    this.$store.dispatch('nowplaying/fetch');
    this.$store.dispatch('nowplaying/subscribe');
    this.startTimer();
  },
  methods: {
    startTimer() {
      if (this.timer) return;
      this.timer = setInterval(() => {
        this.now = Date.now();
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
    openPlayer() {
      this.playerOpen = true;
    },
    hidePlayer() {
      this.playerOpen = false;
    },
    floatingButtonClick() {
      this.openPlayer();
      if (!this.audioPlaying) this.$store.dispatch('audio/toggle');
    },
    async onReaction(reaction) {
      this.$store.dispatch('nowplaying/react', reaction);
    },
  },
  watch: {
    audioPlaying: {
      immediate: true,
      handler() {
        this.audioPlaying ? this.startTimer() : this.stopTimer();
      },
    },
    progressOfShow() {
      if (this.progressOfShow >= 99) {
        this.$store.dispatch('nowplaying/fetch');
      }
    },
  },
};
</script>
<style scoped>
/* Floating button */
.floating-audio-player__floating-button {
  position: absolute;
  right: 16px;
  bottom: 16px;
}

/* Floating player */
.floating-audio-player {
  position: fixed;
  bottom: calc(51px + env(safe-area-inset-bottom));
  left: 0;
  right: 0;
}
.floating-audio-player__content {
  background-color: var(--er-gray-secondary);
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.dark .floating-audio-player__content {
  background-color: var(--er-gray-secondary);
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.55);
}

/* Show item details */
.csn-show-list-item {
  text-align: left;
  position: relative;
}
.csn-show-list-item__content {
  display: flex;
  gap: 12px;
}
.csn-show-list-item__image-holder {
  border-radius: 4px;
  overflow: hidden;
  height: 50px;
  width: 50px;
}
.csn-show-list-item__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.csn-show-list-item__titles {
  min-width: 0;
  flex: 1;
  align-self: center;
}
.csn-show-list-item__title {
  color: var(--cs-gray-07);
}
.csn-show-list-item__subtitle {
  color: var(--cs-gray-05);
}

/* Progress bar */
.floating-audio-player__progress-holder {
  margin: 8px 0;
}
.floating-audio-player__progress {
  background-color: var(--cs-gray-03);
}
.floating-audio-player__progress ::v-deep .er-progress-bar__fill {
  background-color: var(--cs-primary-base);
}
.dark .floating-audio-player__progress ::v-deep .er-progress-bar__fill {
  background-color: var(--cs-gray-06);
}

.floating-audio-player__no-show {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  color: var(--cs-gray-08);
}

/* Bottom bar with time and hide button */
.floating-audio-player__bottom-row {
  display: flex;
  justify-content: space-between;
}
.floating-audio-player__reaction-holder {
  position: relative;
  width: 80px;
}
.floating-audio-player__like,
.floating-audio-player__dislike {
  display: inline-block;
  padding: 8px;
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  bottom: -12px;
  left: 0;
}
.floating-audio-player__dislike {
  transform: rotate(180deg) scaleX(-1);
  left: unset;
  right: 0;
}
.floating-audio-player__time,
.floating-audio-player__hide {
  color: var(--cs-gray-04);
  font-weight: bold;
  padding: 8px;
  margin: -8px;
}

/* Animations */
.fade-in-out-enter-active,
.fade-in-out-leave-active {
  transition: opacity 0.2s;
}
.fade-in-out-enter,
.fade-in-out-leave-to {
  opacity: 0;
}
.slide-in-out-enter-active,
.slide-in-out-leave-active {
  transition: transform 0.2s;
}
.slide-in-out-enter,
.slide-in-out-leave-to {
  transform: translate3d(0, calc(150% + env(safe-area-inset-bottom)), 0);
}
</style>
